import { Permission } from "@edgetier/types";
import View from "constants/view";
import { FeatureToggles } from "redux/modules/setup/setup.types";
import { IRouteHandle, IRouteHierarchy, PageNames } from "types-for/routes";

const RoutesHierarchy: IRouteHierarchy = {
    Root: {
        children: {
            [PageNames.Login]: {
                path: "/",
                lazy: () => import("components/login/login"),
            },
            [PageNames.CallbackOAuth]: {
                path: View.CallbackOAuth,
                lazy: () => import("components/callbacks/oauth/oauth-callback"),
            },
            [PageNames.Authenticate]: {
                path: "/authenticate",
                lazy: () => import("components/authenticate/authenticate"),
            },
            [PageNames.AuthenticatedRedirect]: {
                path: "/authenticated-redirect",
                lazy: () => import("components-for/authentication/authenticated-redirect"),
            },
            [PageNames.Main]: {
                path: "/main",
                lazy: () => import("components/application/authenticated-routes/authenticated-routes"),
                children: {
                    MainRoot: {
                        path: "main",
                    },
                    [PageNames.AgentHome]: {
                        path: "home",
                        lazy: () => import("components/agent-home/agent-home"),
                        handle: {
                            permission: [Permission.HandleInteraction],
                        },
                    },
                    [PageNames.Chat]: {
                        path: "chat",
                        lazy: () => import("components/chat/chat"),
                        handle: {
                            permission: [Permission.HandleInteraction],
                            enabled: {
                                featureToggleKey: FeatureToggles.ChatFunctionalityEnabled,
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.CreateEmail]: {
                        path: "new-email",
                        lazy: () => import("components/create-email/create-email"),
                        handle: {
                            permission: [Permission.HandleInteraction],
                            enabled: {
                                featureToggleKey: FeatureToggles.EmailFunctionalityEnabled,
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.DeferredQueries]: {
                        path: "deferred-queries",
                        lazy: () => import("components/deferred-queries/deferred-queries"),
                        handle: {
                            permission: [Permission.HandleInteraction],
                            enabled: {
                                featureToggleKey: FeatureToggles.EmailFunctionalityEnabled,
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.Email]: {
                        path: "query",
                        lazy: () => import("components/query/query"),
                        handle: {
                            permission: [Permission.HandleInteraction],
                            enabled: {
                                featureToggleKey: FeatureToggles.EmailFunctionalityEnabled,
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.LogicFlows]: {
                        path: "flows",
                        lazy: () => import("components/logic-flows/logic-flows"),
                        handle: {
                            permission: [Permission.EditLogic],
                        },
                    },
                    [PageNames.LogicFlow]: {
                        path: "flow",
                        lazy: () => import("components/logic-flow/logic-flow"),
                        handle: {
                            permission: [Permission.EditLogic],
                        },
                    },
                    [PageNames.Templates]: {
                        path: "templates",
                        lazy: () => import("components/templates/templates"),
                        handle: {
                            permission: [Permission.ViewTemplate],
                        },
                    },
                    [PageNames.ProactiveChat]: {
                        path: "proactive-chat",
                        lazy: () => import("components/proactive-chat/proactive-chat"),
                        handle: {
                            permission: [Permission.HandleInteraction],
                            enabled: {
                                featureToggleKey: FeatureToggles.ProactiveChatEnabled,
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.QueryHistory]: {
                        path: "query-history",
                        lazy: () => import("components/query-history-thread-page/query-history-thread-page"),
                        handle: {
                            permission: [Permission.ViewSearch],
                        },
                    },
                    [PageNames.Permissions]: {
                        path: "permissions",
                        lazy: () => import("components/permissions"),
                        handle: {
                            permission: [Permission.EditRole],
                        },
                    },
                    [PageNames.Users]: {
                        path: "users",
                        lazy: () => import("components/users/users"),
                        handle: {
                            permission: [Permission.EditUser],
                        },
                    },
                    [PageNames.Reporting]: {
                        path: "reporting",
                        lazy: () => import("components/reporting/reporting"),
                        handle: {
                            permission: [Permission.ViewReporting],
                        },
                        children: {
                            [PageNames.ReportingChat]: {
                                path: "chat",
                                lazy: () => import("components/reporting-chat"),
                                handle: {
                                    enabled: {
                                        featureToggleKey: FeatureToggles.ChatFunctionalityEnabled,
                                        validation: Boolean,
                                    },
                                },
                            },
                            [PageNames.ReportingQueue]: {
                                path: "queue",
                                lazy: () => import("components/reporting-queue/reporting-queue"),
                                handle: {
                                    enabled: {
                                        featureToggleKey: FeatureToggles.EmailFunctionalityEnabled,
                                        validation: Boolean,
                                    },
                                },
                            },
                            [PageNames.ReportingHistoric]: {
                                path: "historic",
                                lazy: () => import("components/reporting/reporting-historic"),
                            },
                            [PageNames.ReportingReports]: {
                                path: "reports",
                                lazy: () => import("components/reporting/reporting-reports/"),
                                children: {
                                    [PageNames.ReportingReportsNew]: {
                                        path: "new",
                                        lazy: () => import("components/reporting/reporting-reports/pages/new-report"),
                                    },
                                    [PageNames.ReportingReportsEdit]: {
                                        path: ":reportId/edit",
                                        lazy: () => import("components/reporting/reporting-reports/pages/edit-report"),
                                    },
                                    [PageNames.ReportingReportsCopy]: {
                                        path: ":reportId/copy",
                                        lazy: () => import("components/reporting/reporting-reports/pages/copy-report"),
                                    },
                                    [PageNames.ReportingReportsDisplay]: {
                                        path: ":reportId",
                                        lazy: () =>
                                            import("components/reporting/reporting-reports/pages/display-report"),
                                    },
                                },
                            },
                            [PageNames.ReportingPublicEmail]: {
                                path: "public-email",
                                lazy: () => import("components/reporting/reporting-public-email"),
                                handle: {
                                    enabled: {
                                        featureToggleKey: FeatureToggles.EmailFunctionalityEnabled,
                                        validation: Boolean,
                                    },
                                },
                            },
                            [PageNames.ReportingPublicChat]: {
                                path: "public-chat",
                                lazy: () => import("components/reporting/reporting-public-chat"),
                                handle: {
                                    enabled: {
                                        featureToggleKey: FeatureToggles.ChatFunctionalityEnabled,
                                        validation: Boolean,
                                    },
                                },
                            },
                            [PageNames.CallWallboard]: {
                                path: "call-wallboard",
                                lazy: () => import("components/reporting/call-wallboard"),
                                handle: {
                                    enabled: {
                                        featureToggleKey: FeatureToggles.CallFunctionalityEnabled,
                                        validation: Boolean,
                                    },
                                },
                            },
                            [PageNames.ReportingAgents]: {
                                path: "agents",
                                lazy: () => import("components/reporting/live-agents"),
                            },
                        },
                    },
                    [PageNames.Search]: {
                        path: "search",
                        lazy: () => import("components/search/search"),
                        handle: {
                            permission: [Permission.ViewSearch],
                        },
                        children: {
                            [PageNames.SearchInteractions]: {
                                path: "interactions",
                                lazy: () => import("components/search-emails/search-emails"),
                            },
                            [PageNames.SearchQueries]: {
                                path: "queries",
                                lazy: () => import("components/search-emails/search-emails"),
                            },
                        },
                    },
                    [PageNames.Settings]: {
                        path: "settings",
                        lazy: () => import("components/settings/settings"),
                        children: {
                            [PageNames.SettingsBans]: {
                                path: "bans",
                                lazy: () => import("components/settings/bans"),
                                handle: {
                                    permission: [Permission.EditChatBan],
                                },
                            },
                            [PageNames.SettingsBrands]: {
                                path: "brands",
                                lazy: () => import("components/settings/brands"),
                                handle: {
                                    permission: [Permission.EditBrand],
                                },
                            },
                            [PageNames.SettingsChatWidgetAppearances]: {
                                path: "chat-widget-appearances",
                                lazy: () => import("components/settings/chat-widget-appearances"),
                                handle: {
                                    permission: [Permission.EditWidget],
                                },
                            },
                            [PageNames.SettingsForms]: {
                                path: "forms",
                                lazy: () => import("components/settings/forms"),
                                handle: {
                                    permission: [Permission.EditForm],
                                },
                                children: {
                                    [PageNames.SettingsFormsEdit]: {
                                        path: ":formId",
                                        lazy: () => import("components/settings/forms"),
                                    },
                                },
                            },
                            [PageNames.SettingsSetups]: {
                                path: "setups",
                                lazy: () => import("components/settings/setups"),
                                handle: {
                                    permission: [Permission.EditWidget],
                                },
                            },
                            [PageNames.SettingsLanguages]: {
                                path: "languages",
                                lazy: () => import("components/settings/languages"),
                                handle: {
                                    permission: [Permission.EditLanguage],
                                },
                            },
                            [PageNames.SettingsSystem]: {
                                path: "system",
                                lazy: () => import("components/settings/system"),
                                handle: {
                                    permission: [Permission.EditSystemSetting],
                                },
                            },
                            [PageNames.SettingsThemes]: {
                                path: "themes",
                                lazy: () => import("components/settings/themes"),
                                handle: {
                                    permission: [Permission.EditTheme],
                                },
                            },
                            [PageNames.SettingsEmailRoutingRules]: {
                                path: "email-routing-rules",
                                lazy: () => import("components/settings/email-routing-rules"),
                                handle: {
                                    permission: [Permission.EditEmailRoutingRule],
                                },
                            },
                            [PageNames.SettingsEmailAccounts]: {
                                path: "email-accounts",
                                lazy: () => import("components/settings/email-accounts"),
                                handle: {
                                    permission: [Permission.EditEmailAccount],
                                },
                            },
                            [PageNames.SettingsDepartments]: {
                                path: "departments",
                                lazy: () => import("components/settings/departments"),
                                handle: {
                                    permission: [Permission.EditDepartment],
                                },
                            },
                            [PageNames.SettingsSkills]: {
                                path: "skills",
                                lazy: () => import("components/settings/skills"),
                                handle: {
                                    permission: [Permission.EditSkill],
                                },
                            },
                            [PageNames.SettingsUserStates]: {
                                path: "user-states",
                                lazy: () => import("components/settings/user-states"),
                                handle: {
                                    permission: [Permission.EditAgentState],
                                },
                            },
                            [PageNames.SettingsTextLocalisations]: {
                                path: "text-localisations",
                                lazy: () => import("components/settings/text-localisations"),
                                handle: {
                                    permission: [Permission.EditTextLocalisation],
                                },
                                children: {
                                    [PageNames.SettingsTextLocalisationsEdit]: {
                                        path: ":languageId",
                                        lazy: () => import("components/settings/text-localisations"),
                                    },
                                },
                            },
                        },
                    },
                },
            },
            [PageNames.NoMatch]: {
                path: "*",
                lazy: () => import("@edgetier/client-components").then((module) => ({ default: module.NoMatch })), // TODO: make a proper no match page.
            },
        },
    },
};

/**
 * Function to get the handle object of a given PageName
 * @param pageName      The page name of the route we want the handle for.
 * @param hierarchy     The routes hierarchy to search in. Defaults to the app's routes hierarchy.
 * @returns             The handle object of the given PageName, or null if not found.
 */
export const getRouteHandle = (
    pageName: PageNames,
    hierarchy: IRouteHierarchy = RoutesHierarchy
): IRouteHandle | null => {
    const pageConfig = hierarchy[pageName];

    if (typeof pageConfig?.handle !== "undefined") {
        return pageConfig.handle;
    }

    for (const key in hierarchy) {
        if (hierarchy[key].children) {
            const nestedHandle = getRouteHandle(pageName, hierarchy[key].children);
            if (nestedHandle !== null) {
                return nestedHandle;
            }
        }
    }

    return null;
};

/**
 * Function to get the absolute path of a given PageName
 * @param pageName      The page name of the route we want the absolute path for.
 * @param hierarchy     The routes hierarchy to search in. Defaults to the app's routes hierarchy.
 * @returns             A function that can be called to get the absolute path of a given PageName. If the provided route has parameters, just send them to the function returned by this function.
 */
const getAbsolutePath = (pageName: PageNames, hierarchy: IRouteHierarchy = RoutesHierarchy): string | null => {
    const pageConfig = hierarchy[pageName];
    if (pageConfig) {
        return pageConfig.path || "/";
    } else {
        for (const key in hierarchy) {
            if (hierarchy[key].children) {
                const nestedPath = getAbsolutePath(pageName, hierarchy[key].children);
                if (nestedPath) {
                    return (hierarchy[key].path || "") + (nestedPath[0] === "/" ? nestedPath : `/${nestedPath}`);
                }
            }
        }
    }
    return null;
};

const AbsolutePaths: { [pageName in PageNames]: (...args: Array<string>) => string } = Object.keys(PageNames).reduce(
    (acc, pageName) => {
        const pageNameKey = pageName as PageNames;
        const path = getAbsolutePath(pageNameKey, RoutesHierarchy)?.replace("//", "/");
        if (!path) {
            throw new Error(`No path found for page name ${pageNameKey}`);
        }
        if (pageNameKey !== PageNames.NoMatch) {
            acc[pageNameKey] = (...args: Array<string>) => {
                let localPath = path;
                const paramMatches = localPath.match(/:\w+\??/g);
                if (paramMatches && paramMatches.length === args.length) {
                    paramMatches.forEach((param, index) => {
                        localPath = localPath.replace(param, args[index] || "");
                    });
                }
                return localPath;
            };
        }
        return acc;
    },
    {} as { [pageName in PageNames]: (...args: Array<string>) => string }
);

/**
 * Retrieve the relative path to a specific page from a given origin page.
 * @param currentPageName PageName of the origin page.
 * @param destinationPageName PageName of the destination page.
 * @param hierarchy The routes hierarchy to search in. Defaults to the app's routes hierarchy.
 * @returns The relative path to the destination page from the origin page.
 */
const getRelativePath = (
    currentPageName: PageNames,
    destinationPageName: PageNames,
    hierarchy?: IRouteHierarchy
): string | null => {
    const currentAbsPath = getAbsolutePath(currentPageName, hierarchy);
    const destinationAbsPath = getAbsolutePath(destinationPageName, hierarchy);

    if (!currentAbsPath || !destinationAbsPath) {
        return null;
    }

    const currentParts = currentAbsPath.split("/");
    const destinationParts = destinationAbsPath.split("/");

    let i = 0;
    while (i < currentParts.length && i < destinationParts.length && currentParts[i] === destinationParts[i]) {
        i++;
    }

    const backPaths = new Array(currentParts.length - i).fill("..");
    const forwardPaths = destinationParts.slice(i);

    return [...backPaths, ...forwardPaths].join("/");
};

export { AbsolutePaths, getRelativePath };

export default RoutesHierarchy;
