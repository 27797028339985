import { useLayoutEffect, useState } from "react";

/**
 * Check if the input element is overflowed.
 * @param inputElement  Input element.
 * @param selectedItems The currently Selected items.
 * @returns Whether a boolean indicating if the input element is overflowed.
 */
export const useIsInputOverflowed = <IItem extends {}>(
    inputElement: HTMLInputElement | null,
    selectedItems: IItem[]
) => {
    const [isOverflowed, setIsOverflowed] = useState(false);

    useLayoutEffect(() => {
        if (inputElement !== null) {
            const initialValue = inputElement.value;

            inputElement.value = inputElement.placeholder;
            const isOverflowed = inputElement.scrollWidth - inputElement.clientWidth > 2;
            inputElement.value = initialValue;

            setIsOverflowed(isOverflowed);
        }
    }, [inputElement, selectedItems]);

    return isOverflowed;
};
