import { ElementType, FunctionComponent, memo, useRef } from "react";
import { useIsOverflowed } from "@edgetier/utilities";

import type { ITextProps } from "./text.types";
import Tooltip from "../tooltip";

/**
 * Component for displaying an element with optional tooltip rendered when element overflows.
 * @param {string} props.as - The HTML element type to render as.
 * @param {ReactNode} props.children - The content to display.
 * @param {boolean} props.hideTooltip - Flag to hide the tooltip.
 * @param {string} props.tooltipText - The text for the tooltip.
 * @param {string} props.tooltipClassName - The class name for the tooltip.
 * @param {number} props.tooltipDelayEnter - The delay for tooltip entry.
 * @returns {JSX.Element} Text component.
 */
const Text: FunctionComponent<ITextProps> = ({
    as,
    children,
    hideTooltip,
    tooltipText,
    tooltipClassName,
    tooltipDelayEnter,
    ...otherProps
}) => {
    const textRef = useRef(null);
    const isWidthOverflowed = useIsOverflowed(textRef, "width");
    const isHeightOverflowed = useIsOverflowed(textRef, "height");

    const Element: ElementType = as || "span";

    return (
        <Tooltip
            content={tooltipText || children}
            className={tooltipClassName}
            delayEnter={tooltipDelayEnter ?? 250}
            disableTooltip={hideTooltip || (!isWidthOverflowed && !isHeightOverflowed)}
            useArrow
        >
            {typeof children === "function" ? (
                <Element {...otherProps}>{children(textRef)}</Element>
            ) : (
                <Element ref={textRef} {...otherProps}>
                    {children}
                </Element>
            )}
        </Tooltip>
    );
};

export default memo(Text);
